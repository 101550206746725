import React from 'react';
import styled, { css } from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { media } from '@/utils/mixin';
import { Marker as MarkerType } from '@/typings/model';

import Marker from './components/Marker';
import { mapProps } from './helpers';

interface Props {
  markers: MarkerType[];
}

function DealersMap({ markers }: Props) {
  return (
    <MapContainer>
      <Wrapper>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
          }}
          center={mapProps.center}
          defaultZoom={mapProps.zoom}
        >
          {markers?.map(({ name, address, latitude, longitude }, index) => (
            <Marker
              key={index}
              address={address}
              name={name}
              lat={latitude}
              lng={longitude}
            />
          ))}
        </GoogleMapReact>
      </Wrapper>
    </MapContainer>
  );
}

export default DealersMap;

const MapContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${media.mobile(css`
    display: none;
  `)}
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  position: sticky;
  top: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  max-height: 100vh;
`;
