import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import BodyCell from './components/BodyCell';
import { BodyTableProps } from './types';

function BodyTable({
  name,
  address,
  divisions,
  phones,
  socialYoutube,
  socialInstagram,
  socialOdnoklassnikiUrl,
  socialFacebookUrl,
  socialVkontakteUrl,
  socialTelegramUrl,
  socialViberUrl,
  socialSkypeUrl,
}: BodyTableProps) {
  return (
    <Component>
      {name && <DealerName>{name}</DealerName>}
      <Body>
        <BodyCell title="Адрес" address={address} />
        <BodyCell title="Сервисы" divisions={divisions} />
        <BodyCell title="Телефон" phones={phones} />
        <BodyCell
          title="Социальные сети"
          socialFacebookUrl={socialFacebookUrl}
          socialVkontakteUrl={socialVkontakteUrl}
          socialInstagram={socialInstagram}
          socialOdnoklassnikiUrl={socialOdnoklassnikiUrl}
          socialYoutube={socialYoutube}
          socialTelegramUrl={socialTelegramUrl}
          socialViberUrl={socialViberUrl}
          socialSkypeUrl={socialSkypeUrl}
        />
      </Body>
    </Component>
  );
}

export default BodyTable;

const Component = styled.div`
  padding: 10px 20px;
  background: ${(props) => props.theme.white};

  ${media.tabletSmall(css`
    padding: 10px;
  `)}

  ${media.mobile(css`
    padding: 5px 20px;
  `)}

  &:not(:first-child) {
    margin-top: 30px;

    ${media.mobile(css`
      margin-top: 0;
    `)}
  }
`;

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  ${media.mobile(css`
    flex-direction: column;
    flex-wrap: nowrap;
  `)}
`;

const DealerName = styled.span`
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 2rem;
  margin-left: 10px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    margin-left: 0;
  `)}
`;
